<template>
  <div>
    <loading :loading="loading"></loading>
    <div class="row">
      <div class="col-sm-12">
        <form novalidate v-on:submit.prevent>
          <div class="row">
            <div class="col-sm-12">
              <h2>Change Password</h2>
            </div>
          </div>
          <div class="row" v-if="message">
            <div class="col-sm-12">
              <span class="text-warning">{{message}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="alert alert-danger" v-if="anyErrors" role="alert">
                <span v-for="error in errors" v-bind:key="error">{{error}}</span>
              </div>
              <change-password-inputs
                v-model="passwords"
                v-bind:user-id="query.userId"
                :default-password-complexity="passwordComplexity"
              ></change-password-inputs>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <button
                class="btn btn-primary"
                @click="submit"
                :disabled="!passwords.valid"
                type="submit"
              >Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios'
import changePasswordInputs from '@/Components/UserSettings/ChangePassword/ChangePasswordInputs'

export default {
  name: 'resetPassword',
  props: ['query', 'userId', 'passwordComplexity', 'message'],
  components: {
    'change-password-inputs': changePasswordInputs
  },
  computed: {
    anyErrors () {
      // some with this will return true if anything exists in JS
      return this.errors.some(x => x)
    }
  },
  data () {
    return {
      passwords: {
        password: '',
        confirmPassword: '',
        valid: false
      },
      errors: [],
      loading: false
    }
  },
  methods: {
    async submit () {
      try {
        var response = await axios.post('Account/ChangePassword', { UserId: this.userId, Code: this.query.code, Password: this.passwords.password, ConfirmPassword: this.passwords.confirmPassword }, { showload: true })

        if (response.data.errorMessage) {
          this.errors = [response.data.errorMessage]
        } else if (response.data.errors) {
          this.errors = response.data.errors
        } else {
          this.errorMessage = null
          switch (response.data.response) {
            case 'Success':
              location.replace(process.env.VUE_APP_ROOT_API + this.query.returnUrl)
              break
            case 'LockedOut':
              this.accountLockedMessage = 'Account is Locked, Please try again later'
              break
            case 'LockedOutPerm':
              this.accountLockedMessage = 'Account is Locked, due to inactivity. Please contact your system administrator'
              break
          }
        }
      } catch (e) {

      }
    }
  }
}
</script>
