var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loading", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "form",
            {
              attrs: { novalidate: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _vm._m(0),
              _vm.message
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c("span", { staticClass: "text-warning" }, [
                        _vm._v(_vm._s(_vm.message))
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12" },
                  [
                    _vm.anyErrors
                      ? _c(
                          "div",
                          {
                            staticClass: "alert alert-danger",
                            attrs: { role: "alert" }
                          },
                          _vm._l(_vm.errors, function(error) {
                            return _c("span", { key: error }, [
                              _vm._v(_vm._s(error))
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                    _c("change-password-inputs", {
                      attrs: {
                        "user-id": _vm.query.userId,
                        "default-password-complexity": _vm.passwordComplexity
                      },
                      model: {
                        value: _vm.passwords,
                        callback: function($$v) {
                          _vm.passwords = $$v
                        },
                        expression: "passwords"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { disabled: !_vm.passwords.valid, type: "submit" },
                      on: { click: _vm.submit }
                    },
                    [_vm._v("Submit")]
                  )
                ])
              ])
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("h2", [_vm._v("Change Password")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }